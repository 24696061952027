<style scoped>
	.content {
		width: 1320px;
		margin: 0 auto;
		padding-top: 103px;
		position: relative;
	}
	.content > img {
		width: 1105px;
		height: 645px;
	}
	.con {
		position: absolute;
		top: 200px;
		right: 0;
		width: 480px;
	}
	.con-text {
		font-size: 30px;
		color: #222;
		font-weight: bold;
		margin-top: 50px;
	}
	.con-text2 {
		font-size: 25px;
		margin-top: 45px;
		color: #222;
		margin-bottom: 35px;
	}
	.con-text3 {
		font-size: 15px;
		line-height: 25px;
		color: #707070;
	}
	.con-div2 {
		width: 180px;
		height: 42px;
		line-height: 42px;
		background: #75B9F6;
		font-size: 21px;
		color: #fff;
		text-align: center;
		margin-top: 82px;
	}
	.content2 {
		width: 1320px;
		margin: 180px auto 105px;
		text-align: center;
	}
	.con-div {
		width: 65px;
		height: 8px;
		background: #75B9F6;
		margin-bottom: 115px;
	}
	.con-p {
		font-size: 31px;
		color: #222;
		font-weight: bold;
	}
	.con-p2 {
		font-size: 16px;
		color: #666;
		margin-top: 57px;
		font-weight: bold;
	}
	/*  */
	.bottom-bg {
		width: 100%;
		min-width: 1320px;
		margin-top: 110px;
		background: #000;
	}
	
	.bottomInner {
		width: 1320px;
		height: 420px;
		margin: 0 auto;
		padding: 0 60px;
	}
	
	.bottomInner>div {
		height: 420px;
		width: calc(100% / 4);
	}
	.bottom-item {
		background-size: 100%;
		height: 100%;
		flex-direction: column;
		position: relative;
	}
	.posi-bgColor {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.posi-bgColor:hover {
		background: #6DA3D6;
		opacity: .8;
		/* z-index: 0; */
	}
	.bottom-list{
		width: 100%;
		z-index: 6;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.bottom_item_img1 {
		width: 48px;
		height: 38px;
	}
	.bottom_item_img2 {
		width: 48px;
		height: 45px;
	}
	.bottom_item_img3 {
		width: 32px;
		height: 48px;
	}
	.bottom_item_img4 {
		width: 48px;
		height: 48px;
	}
	.bottom_div {
		width: 24px;
		height: 1px;
		background-color: #fff;
		margin: 26px 0;
	}
	.bottom-item > p {
		font-size: 15px;
		color: #fff;
	}
	.content4 {
		width: 1320px;
		padding: 0 15px 40px;
		margin: 0 auto;
	}
	.con4-div {
		margin-top: 100px;
		flex-wrap: wrap;
	}
	.con4-div > div:nth-child(4n) {
		margin-right: 0;
	}
	.con4-item {
		flex-direction: column;
		margin-bottom: 110px;
		width: 140px;
		text-align: center;
		margin-right: 150px;
	}
	.con4-item > img {
		width: 70px;
		height: 69px;
		margin-bottom: 30px;
	}
	.con4-title1 {
		font-size: 18px;
		color: #222;
		font-weight: bold;
	}
	.con4-title2 {
		font-size: 12px;
		line-height: 18px;
		color: #666;
		margin-top: 20px;
	}
	
	.box-4 {
		width: 1320px;
		padding: 0 150px 150px;
		flex-wrap: wrap;
		margin: 0 auto;
	}
	
	.box4-p {
		text-align: center;
		color: #666;
		font-size: 14px;
		line-height: 18px;
	}
	
	.box4-item {
		height: 140px;
		width: 140px;
		flex-direction: column;
		margin-top: 90px;
		position: relative;
	}
	
	.box4-item>img {
		width: 63px;
		height: 63px;
	}
	
	.box-4>img {
		width: 59px;
		height: 33px;
	}
	.box4-item-num {
		position: absolute;
		bottom: -18px;
		left: -50px;
		font-size: 79px;
		color: #DBEBFC;
		font-weight: bold;
	}
</style>

<template>
	<div>
		<div class="content flex">
			<img src="../../../assets/images/webSite-1.png" alt="">
			<div class="con">
				<div class="con-div"></div>
				<p class="con-text">互联网定制型网络</p>
				<p class="con-text2">让使用广大网民，更便捷的找到您的网站</p>
				<p class="con-text3">互联网营销型网站配合搜索引擎（SEO）优化，可让搜索引擎高效的 收录您的网站，对网站有针对性对引入流量；让您的互联网营销、品牌营销、转化率增加，有更多的上升空间。</p>
				<div class="con-div2">立即定制</div>
			</div>
		</div>
		<div class="content2">
			<div class="con-div" style="margin: 0 auto 115px;"></div>
			<p class="con-p">定制网站能给您带来什么</p>
			<p class="con-p2">致力于的互联网营销效果转换</p>
		</div>
		<div class="bottom-bg">
			<div class="bottomInner flex">
				<div class="bottom-item flexCenter" :style="{'background-image': 'url(' + item.bg + ')'}" v-for="(item, index) in bottomBgList" :key="index">
					<div class="bottom-list">
					<img :class="`bottom_item_img${index + 1}`" :src="item.icon">
					<div class="bottom_div"></div>
					<p>{{item.text}}</p>
					<p v-if="item.text_1">{{item.text_1}}</p>
					<p v-if="item.text_2">{{item.text_2}}</p>
						</div>
					<div class="posi-bgColor"></div>
				</div>
			</div>
		</div>
		<div class="content2">
			<div class="con-div" style="margin: 0 auto 115px;"></div>
			<p class="con-p">我们的定制网站具备哪些特点</p>
			<p class="con-p2">点对点定制网站服务，完美成就您的要求</p>
		</div>
		<div class="content4">
			<div class="con4-div flex-between">
				<div class="con4-item flex-y-center" v-for="(item, index) in content4List" :key="index">
					<img :src="item.img" alt="">
					<p class="con4-title1">{{item.text}}</p>
					<p class="con4-title2">{{item.text2}}</p>
				</div>
			</div>
		</div>
		<div class="content2" style="margin: 60px auto 105px">
			<div class="con-div" style="margin: 0 auto 115px;"></div>
			<p class="con-p">网站开发流程</p>
			<p class="con-p2">一对一设计服务，优质技术保障</p>
		</div>
		<div class="box-4 flex-x-center flex-between">
			<div class="flex" v-for="(item, index) in navList4" :key="index">
				<div class="box4-item flex-between flexCenter">
					<img :src="item.icon" alt="">
					<div class="box4-p">
						<p>{{item.text}}</p>
						<p>{{item.text_2}}</p>
					</div>
					<div class="box4-item-num">{{index + 1}}</div>
				</div>
				<div v-if="index != 2 && index != 5" class="flex-y-center" style="margin: 90px 100px 0"><img src="../../../assets/images/index-box4-icon7.png"></div>
			</div>
		</div>
		<Button></Button>
	</div>
</template>

<script>
	import Button from "../../../../components/bottom.vue";
	export default {
		name: "customWebsite",
		data() {
			return {
				bottomBgList: [
					{bg: require("../../../assets/images/company-img6.png"), icon: require("../../../assets/images/company-img10.png"), text: "传播", text_1: "提升关注度",text_2: "打动潜在客户"},
					{bg: require("../../../assets/images/company-img7.png"), icon: require("../../../assets/images/company-img11.png"), text: "品牌", text_1: "树立品牌形象",text_2: "增强信任感"},
					{bg: require("../../../assets/images/company-img8.png"), icon: require("../../../assets/images/company-img12.png"), text: "便捷", text_1: "快速交付",text_2: "节约大量时间人力"},
					{bg: require("../../../assets/images/company-img9.png"), icon: require("../../../assets/images/company-img13.png"), text: "访问", text_1: "高曝光率高精准度",text_2: "用户随时随地获取信息"}
				],
				content4List: [
					{text: "视觉设计", text2: "一对一设计师对接为您的网站添砖加瓦", img: require("../../../assets/images/webSite-2.png")},
					{text: "多平台展现", text2: "布局互联网让更多客户知道您", img: require("../../../assets/images/webSite-3.png")},
					{text: "技术支持", text2: "符合当前主流建站技术高效果的网站性能", img: require("../../../assets/images/webSite-4.png")},
					{text: "SEO搜索优化", text2: "从搜索引擎中获得更多的客户流量", img: require("../../../assets/images/webSite-5.png")},
					{text: "信息获取", text2: "性能安全云主机助力网站好基础", img: require("../../../assets/images/webSite-6.png")},
					{text: "性能安全", text2: "性能安全云主机助力网站好基础", img: require("../../../assets/images/webSite-7.png")},
					{text: "安全防护", text2: "安全防御云服务打造安全性的网站", img: require("../../../assets/images/webSite-8.png")},
					{text: "客服服务", text2: "为客户提供优质服务让客户获取更舒服的感知", img: require("../../../assets/images/webSite-9.png")},
				],
				navList4: [{
						icon: require("../../../assets/images/index-box4-icon1.png"),
						text: "顾问咨询",
						text_2: "需求调研分析"
					},
					{
						icon: require("../../../assets/images/index-box4-icon2.png"),
						text: "全案设计",
						text_2: "初期合理设计规划"
					},
					{
						icon: require("../../../assets/images/index-box4-icon3.png"),
						text: "设计验收",
						text_2: "客户进行验收"
					},
					{
						icon: require("../../../assets/images/index-box4-icon4.png"),
						text: "开发制作",
						text_2: "技术开发实现"
					},
					{
						icon: require("../../../assets/images/index-box4-icon5.png"),
						text: "产品测试",
						text_2: "稳定而又周密测试"
					},
					{
						icon: require("../../../assets/images/index-box4-icon6.png"),
						text: "网站上线",
						text_2: "开展网络营销"
					},
				],
			}
		},
		components: {Button}
	}
</script>

